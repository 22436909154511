import request from "@/api/requestNew";

// 添加和编辑城市合伙人
export const addOrUpdate = (data) => request({url: 'city_partner/addOrUpdate', method: 'post', data});
// 城市合伙人列表
export const getPartnerList = (data) => request({url: 'city_partner/partner_list', method: 'post', data});
// 删除城市合伙人
export const deletePartner = (data) => request({url: 'city_partner/delete', method: 'post', data});
// 设置停牌
export const setUserStop = (data) => request({url: 'city_partner/userStopSetting', method: 'post', data});
// 测试停牌
export const testOperateStopBack = (data) => request({url: 'city_partner/testOperateStopBack', method: 'post', data});
export const syPartnerList = (data) => request({url: 'statistic_city_partner/syPartnerList', method: 'post', data});
export const partnerSyTotal = (data) => request({url: 'statistic_city_partner/partnerSyTotal', method: 'post', data});



