<template>
	<div>
		<el-card>
			<el-row class="left mb15" v-if="!isCityAdmin">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="城市名称:">
							<el-input v-model="city_name" placeholder="请输入城市名称" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="openTable">展开/折叠</el-button>
							<el-button type="default" icon="el-icon-refresh-right">刷新</el-button>
						</el-form-item>
					</el-form>
					<!--<el-button type="primary" size="mini" icon="el-icon-plus" @click="addCity">添加</el-button>-->
				</el-col>
			</el-row>
			<el-table :data="cityData" ref="cityRef" style="width: 100%; margin-bottom: 20px" row-key="city_id" border :default-expand-all="isOpen" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
				<el-table-column prop="city_id" label="ID"></el-table-column>
				<el-table-column prop="city_name" label="城市" width="150px"></el-table-column>
				<el-table-column prop="city_bh" label="城市编码" width="100px"></el-table-column>
				<el-table-column prop="lng" label="经度" width="160px"></el-table-column>
				<el-table-column prop="lat" label="纬度" width="160px"></el-table-column>
<!--				<el-table-column prop="fypb_one" label="一级分佣(%)" width="100px"></el-table-column>-->
				<el-table-column label="是否关闭">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.is_del" @change="setCloseStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="city_sort" label="排序序号"></el-table-column>
				<el-table-column fixed="right" label="操作" v-if="isCityAdmin" width="100px">
					<template slot-scope="scope">
						<div >
							<el-button type="success" size="mini" plain @click="goSettings(scope.row)">设置</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" v-else width="225px">
					<template slot-scope="scope">
						<div style="margin-bottom:10px">
							<el-button type="primary" size="mini" plain @click="addCity(scope.row)">添加</el-button>
							<el-button type="primary" size="mini" plain @click="editCity(scope.row)">编辑</el-button>
							<el-button type="success" size="mini" plain @click="goSettings(scope.row)">设置</el-button>
						</div>
						<el-row>
							<el-button size="mini" type="warning" plain @click="bindCard(scope.row)">配置</el-button>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 添加城市对话框 -->
			<el-dialog :title="title" :visible.sync="addCityVisible" width="60%">
				<el-form class="left" :rules="addCityRules" ref="addCityForm" :model="addCityData" label-width="105px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="城市" prop="city_name">
								<el-input v-model="addCityData.city_name" placeholder="请输入城市名称"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="上级城市" prop="parent_id">
								<treeselect v-model="addCityData.parent_id" :options="cityData" :normalizer="normalizer" :show-count="true" placeholder="请选择上级城市" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="城市编码" prop="city_bh">
								<el-input v-model="addCityData.city_bh" placeholder="请输入城市编码"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="一级分佣(%)" prop="fypb_one">
								<el-input v-model.number="addCityData.fypb_one" placeholder="请输入一级分佣比例"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="经度" prop="lng">
								<el-input v-model="addCityData.lng" placeholder="请输入经度"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item label="维度" prop="lat">
								<el-input v-model="addCityData.lat" placeholder="请输入维度"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item>
								<el-button type="primary" plain @click="getJingweidu">获取经纬度</el-button>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="排序序号" prop="city_sort">
								<el-input v-model.number="addCityData.city_sort" placeholder="请输入排序序号"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="center">
					<el-button type="default" @click="addCityVisible=false">取消</el-button>
					<el-button type="primary" @click="subAddCity">确认</el-button>
				</div>
			</el-dialog>
			<Map :jingweiVisible.sync="jingweiVisible" @close="closeMapDialog" @subJINGWEI="subJINGWEI"></Map>
		
			<!-- 绑定结算卡对话框 -->
			<el-dialog :title="title" top="5vh" :visible.sync="bindCardVisible" width="60%">
				<el-form class="left" :rules="bindCardRules" ref="bindCardForm" :model="bindCardData" label-width="120px">
<!--					<el-row class="tip">-->
<!--					  <el-col :span="24">-->
<!--					  	<div class="custom-item">-->
<!--					  		公司信息-->
<!--					  	</div>-->
<!--					  </el-col>-->
<!--					</el-row>-->
<!--					<el-row>-->
<!--						<div class="fare-setting">-->
<!--							<el-col :span="24">-->
<!--					            <el-form-item label="公司名称" prop="company_name">-->
<!--									<el-input v-model.number="bindCardData.company_name" placeholder="请输入公司名称"></el-input>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="公司地址" prop="company_address">-->
<!--									<el-input v-model.number="bindCardData.company_address" placeholder="请输入公司地址"></el-input>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="公司营业执照" prop="company_yyzz">-->
<!--									<div class="previewUpload">-->
<!--										<div v-if="bindCardData.company_yyzz!=''" class="previewItem">-->
<!--											<img :src="bindCardData.company_yyzz" alt="">-->
<!--											<i class="el-icon-close delIcon" @click="delCompanyYyzz"></i>-->
<!--										</div>-->
<!--										<div class="previewItems" v-else>-->
<!--											<el-upload ref="upload" style="width:100px" list-type="picture" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :auto-upload="false" :show-file-list="false" :multiple=true :on-change="handlerChange">-->
<!--												<i class="el-icon-plus previewadd"></i>-->
<!--											</el-upload>-->
<!--										</div>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--							</el-col>-->
<!--						</div>-->
<!--					</el-row>-->
<!--					<el-row class="tip">-->
<!--					  <el-col :span="24">-->
<!--					  	<div class="custom-item">-->
<!--					  		结算银行卡-->
<!--					  	</div>-->
<!--					  	<el-button type="warning" plain size="small" v-if="is_bind" @click="unbind">解绑</el-button>-->
<!--					  </el-col>-->
<!--					</el-row>-->
<!--					<el-row>-->
<!--						<div class="fare-setting">-->
<!--							<el-col :span="24">-->
<!--								<el-form-item label="卡号" prop="card_id">-->
<!--									<el-input v-model="bindCardData.card_id" placeholder="请输入银行卡号" maxlength="21" :disabled="is_bind"></el-input>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="姓名" prop="card_name">-->
<!--									<el-input v-model="bindCardData.card_name" placeholder="请输入银行卡归属人姓名" :disabled="is_bind"></el-input>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="手机号" prop="tel_no">-->
<!--									<el-input v-model="bindCardData.tel_no" placeholder="请输入银行预留手机号" maxlength="11" :disabled="is_bind"></el-input>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="账户类型" prop="bank_acct_type">-->
<!--									<el-select v-model="bindCardData.bank_acct_type" placeholder="账户类型" style="width: 100%;" :disabled="is_bind">-->
<!--										<el-option v-for="item in acct_type" :key="item.value" :label="item.label" :value="item.value">-->
<!--										</el-option>-->
<!--									</el-select>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="开户银行" v-if="bindCardData.bank_acct_type==1">-->
<!--									<el-select v-model="bindCardData.bank_code" clearable filterable remote reserve-keyword placeholder="可输入名称进行搜索" :disabled="is_bind">-->
<!--									    <el-option-->
<!--									      v-for="(item,key) in bank_code_list"-->
<!--									      :key="item.value"-->
<!--									      :label="item.label"-->
<!--									      :value="item.value">-->
<!--									    </el-option>-->
<!--								  	</el-select>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="开户行所在省份" v-if="bindCardData.bank_acct_type==1">-->
<!--									<el-select v-model="bindCardData.prov_code" clearable filterable remote reserve-keyword placeholder="可输入名称进行搜索" @change="setCity" :disabled="is_bind">-->
<!--									    <el-option-->
<!--									      v-for="(item,key) in province_list"-->
<!--									      :key="item.value"-->
<!--									      :label="item.title"-->
<!--									      :value="item.value">-->
<!--									    </el-option>-->
<!--								  	</el-select>-->
<!--								  	<el-select v-model="bindCardData.area_code" style="margin-left: 10px;" :disabled="is_bind">-->
<!--									    <el-option-->
<!--									      v-for="(item,key) in city_list"-->
<!--									      :key="item.value"-->
<!--									      :label="item.title"-->
<!--									      :value="item.value">-->
<!--									    </el-option>-->
<!--								  	</el-select>-->
<!--								</el-form-item>-->
<!--								<el-form-item label="身份证" prop="cert_id" v-if="bindCardData.bank_acct_type==2">-->
<!--									<el-input v-model="bindCardData.cert_id" placeholder="请输入身份证号" maxlength="18" :disabled="is_bind"></el-input>-->
<!--								</el-form-item>-->
<!--							</el-col>-->
<!--						</div>-->
<!--					</el-row>-->
					<el-row class="tip">
					  <el-col :span="24">
					  	<div class="custom-item">
					  		分佣配置
					  	</div>
					  </el-col>
					</el-row>
					<el-row>
						<div class="fare-setting">
							<el-col :span="12">
								<el-form-item label="分佣比例" prop="ptfy_bfb">
									<el-input v-model.number="bindCardData.ptfy_bfb" placeholder="请输入分佣比例"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="物料分佣比例" prop="wlfy_bfb">
									<el-input v-model.number="bindCardData.wlfy_bfb" placeholder="请输入物料分佣比例"></el-input>
								</el-form-item>
							</el-col>
						</div>
					</el-row>
				</el-form>
				<div class="center">
					<el-button type="default" @click="bindCardVisible=false">取消</el-button>
					<el-button type="primary" @click="subBindCard">确认</el-button>
				</div>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import axios from 'axios'
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Map from '@/components/Map/Map.vue'
	export default {
		components: {
			Treeselect,Map
		},
		data() {
			// 验证帐户类型
			var checkBankAcctType = (rule, value, cb) => {
				//对公
				if(value==1){
					if(this.bindCardData.bank_code){
						return cb();
					}else{
						cb(new Error("请选择银行编码"));
					}
					if(this.bindCardData.prov_code){
						return cb();
					}else{
						cb(new Error("请选择开户银行所在省份编码"));
					}
					if(this.bindCardData.area_code){
						return cb();
					}else{
						cb(new Error("请选择开户银行所在地区编码"));
					}
				}else{
					if(this.bindCardData.cert_id){
						return cb();
					}else{
						cb(new Error("请输入证件号"));
					}
				}
			};
			return {
				title: "新增城市",
				total: 0,
				city_name: '',
				isOpen: false,
				isCityAdmin: false,
				addCityData: {
					city_name: '',
					parent_id: null,
					city_bh: '',
					fypb_one: '',
					lng: '',
					lat: '',
					city_sort: '',
				},
				addCityRules: {
					city_name: [{
						required: true,
						message: '请输入城市名称',
						trigger: 'blur'
					}],
					city_bh: [{
						required: true,
						message: '请输入城市编码',
						trigger: 'blur'
					}], 
					fypb_one:[{
						required: true,
						message: '请输入一级分佣比例',
						trigger: 'blur'
					},{ type: "number", message: "请输入数字" },],
					lng: [{
						required: true,
						message: '请输入经度',
						trigger: 'blur'
					}],
					lat: [{
						required: true,
						message: '请输入纬度',
						trigger: 'blur'
					}]
				},
				cityData: [],
				addCityVisible: false,
				jingweiVisible: false,
				is_bind: false,
				bindCardVisible: false,
//				acct_type: [{"label":"对公",value:1},{"label":"对私",value:2}],
				acct_type: [{"label":"对私",value:2}],
				bindCardData: {
					company_name: '',
					company_address: '',
					company_yyzz: '',
					card_id: '',
					cert_id: '',
					tel_no: '',
					card_name: '',
					bank_acct_type: 2,
					bank_code: '',
					prov_code: '',
					area_code: '',
					ptfy_bfb: '',
					wlfy_bfb: '',
				},
				bindCardRules: {
					company_name: [{
						required: true,
						message: '请输入公司名称',
						trigger: 'blur'
					}],
					company_address: [{
						required: true,
						message: '请输入公司地址',
						trigger: 'blur'
					}], 
					company_yyzz:[{
						required: true,
						message: '请选择公司营业执照',
						trigger: 'blur'
					}],
					card_id: [{
						required: true,
						message: '请输入银行卡号',
						trigger: 'blur'
					}],
					card_name: [{
						required: true,
						message: '请输入卡号归属人姓名',
						trigger: 'blur'
					}],
					tel_no: [{
						required: true,
						message: '请输入银行预留手机号',
						trigger: 'blur'
					}],
					ptfy_bfb: [{
						required: true,
						message: '请输入分佣比例',
						trigger: 'blur'
					}],
					wlfy_bfb: [{
						required: true,
						message: '请输入物料分佣比例',
						trigger: 'blur'
					}],
					bank_acct_type: [{
						required: true,
						message: '请选择账户类型',
						trigger: 'blur'
					},{
						validator: checkBankAcctType,
						trigger: 'blur'
					}], 
				},
				fileData: {},
				bank_code_list: [],
				province_list: [],
				city_list: []
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.initBankCard();
				this.getCityList();
			}
		},
		methods: {
			getCityList() {
				var url = 'city/city_list';
				let params = {
					city_name: this.city_name,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.cityData = res.list;
						console.log(that.cityData);
						that.isCityAdmin = res.isCityAdmin;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			initBankCard(){
				var url = 'city/initBankCard';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.bank_code_list = res.bank_code_list;
						that.province_list = res.province_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getCityList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getCityList();
			},
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//查询
			search(){
				this.getCityList();
			},
			//折叠
			openTable() {
				this.isOpen = !this.isOpen;
				this.fold(this.cityData, this.isOpen);
			},
			fold(arr, isOpen) {
				arr.forEach((i) => {
					this.$refs.cityRef.toggleRowExpansion(i, isOpen);
					if(i.children) {
						this.fold(i.children, isOpen);
					}
				});
			},
			addCity(row) {
				this.addCityData = {
					city_name: '',
					city_bh: '',
					fypb_one: '',
					lng: '',
					lat: '',
					city_sort: '',
				};
				this.title = "新增城市";
				this.addCityVisible = true;
				if(row != null && row.city_id) {
					this.addCityData.parent_id = row.city_id;
				} else {
					this.addCityData.parent_id = null;
				}
			},
			editCity(scope) {
				this.title = "编辑城市";
				this.addCityData = scope
				this.addCityVisible = true;
			},
			subAddCity() {
				this.$refs.addCityForm.validate(valid => {
					if(!valid) return;
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						var url = 'city/addOrUpdate';
						let params = that.addCityData;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.addCityVisible = false;
								that.$refs.addCityForm.resetFields();
								that.$message.success('操作成功');
								that.getCityList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 1000);
				})
			},
			setCloseStatus(item) {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'city/deleteCity';
					let params = {
						city_id: item.city_id,
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('操作成功');
							that.getCityList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}, 300);
			},
			goSettings(item){
				this.$router.push({
					path: '/other_settings/settings_city_fenyong',
					query: {
						cityId:item.city_id,
						cityName:item.city_name,
					}
				})
			},
			//打开经纬度弹窗
			getJingweidu() {
				this.jingweiVisible = true
			},
			//关闭经纬度弹窗
			closeMapDialog() {
				this.jingweiVisible = false
			},
			//获取经纬度
			subJINGWEI(lat, lng) {
				this.addCityData.lat = lat
				this.addCityData.lng = lng
				this.jingweiVisible = false
			},
			delCompanyYyzz() {
				this.bindCardData.company_yyzz = '';
			},
			bindCard(item){
				this.title = "佣金配置";
				this.bindCardData = {
					city_id: item.city_id,
					company_name: item.company_name,
					company_address: item.company_address,
					company_yyzz: item.company_yyzz,
					card_id: item.card_id,
					cert_id: item.cert_id,
					tel_no: item.tel_no,
					card_name: item.card_name,
					bank_acct_type: item.bank_acct_type | 2,
					bank_code: item.bank_code,
					prov_code: item.prov_code,
					area_code: item.area_code,
					ptfy_bfb: item.ptfy_bfb,
					wlfy_bfb: item.wlfy_bfb,
				};
				this.is_bind = item.card_id ? true : false;
				this.bindCardVisible = true;
			},
			subBindCard(){
				this.$refs.bindCardForm.validate(valid => {
					if(!valid) return;
					var formData = new FormData();
					formData.append("city_id", that.bindCardData.city_id);
					formData.append("company_yyzz", that.bindCardData.company_yyzz);
					formData.append("company_name", that.bindCardData.company_name);
					formData.append("company_address", that.bindCardData.company_address);
					formData.append("card_id", that.bindCardData.card_id);
					formData.append("tel_no", that.bindCardData.tel_no);
					formData.append("card_name", that.bindCardData.card_name);
					formData.append("ptfy_bfb", that.bindCardData.ptfy_bfb);
					formData.append("wlfy_bfb", that.bindCardData.wlfy_bfb);
					formData.append("bank_acct_type", that.bindCardData.bank_acct_type);
					if(that.bindCardData.bank_acct_type==1){
						formData.append("bank_code", that.bindCardData.bank_code);
						formData.append("prov_code", that.bindCardData.prov_code);
						formData.append("area_code", that.bindCardData.area_code);
					}else{
						formData.append("cert_id", that.bindCardData.cert_id);
					}
					//如果存在文件
					if(this.fileData.raw) {
						formData.append("file", this.fileData.raw, this.fileData.name);
					}
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						const loading = this.$loading({
							lock: true,
							text: '绑定中...',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)'
				        });
						var url = 'city/bindCard';
						axios({
							url: url,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							method: "post",
							data: formData,
						}).then(res => {
							loading.close();
							if(res.data.status) {
								that.bindCardVisible = false;
								that.$refs.bindCardForm.resetFields();
								that.$message.success('操作成功');
								that.getCityList();
							} else {
								that.$message.error(res.data.msg);
							}
						}).catch(error => {
							loading.close();
							that.bindCardVisible = false;
							that.$message.error('网络错误');
						})
					}, 1000);
				})
			},
			//上传
			handlerChange(file) {
				this.bindCardData.company_yyzz = URL.createObjectURL(file.raw);
				this.fileData = file;
			},
			setCity(e){
				let province_info = that.province_list.filter(item=>item.value==e);
				if(province_info.length==1){
					this.city_list = province_info[0].cities;
				}
			},
			unbind(){
				this.$confirm('你确认要解绑该城市的结算卡吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'city/unbindCard';
					let params = {
						city_id: that.bindCardData.city_id,
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.bindCardVisible = false;
							that.$message.success('解绑成功');
							that.getCityList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}).catch(() => {});
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import './Other_settings.scss';
</style>