import Vue from 'vue'
import VueRouter from 'vue-router'
//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const Home = () => import('@/views/Home/Home')
const Login = () => import('@/views/Login/Login')
const Welcome = () => import('@/views/Welcome/Welcome')

const service_add = () => import('@/views/Service/Service_add/Service_add')
const add = () => import('@/views/Service/Service_add/Add')
const service_type = () => import('@/views/Service/Service_type/Service_type')
const service_banner = () => import('@/views/Service/Service_banner/Service_banner')

const technician_level = () => import('@/views/Technician/Technician_level/Technician_level')
const technician_manage = () => import('@/views/Technician/Technician_manage/Technician_manage')
const technician_manage_add = () => import('@/views/Technician/Technician_manage/TechAdd')
const technician_zhaopin = () => import('@/views/Technician/Technician_zhaopin/Technician_zhaopin')
const technician_sect = () => import('@/views/Technician/Technician_sect/Technician_sect')
const technician_yijian = () => import('@/views/Technician/Technician_yijian/Technician_yijian')
const technician_verify = () => import('@/views/Technician/Technician_verify/Technician_verify')

const user_list = () => import('@/views/User/User_list/User_list')
const user_yijian = () => import('@/views/User/User_yijian/User_yijian')

const vip_extension = () => import('@/views/Vip/Vip_extension/Vip_extension')
const vip_level = () => import('@/views/Vip/Vip_level/Vip_level')
const vip_list = () => import('@/views/Vip/Vip_list/Vip_list')
const vip_chongzhi = () => import('@/views/Vip/Vip_chongzhi/Vip_chongzhi')
const vip_warning = () => import('@/views/Vip/Vip_warning/Vip_warning')

const agent_add = () => import('@/views/Agent/Agent_add/Agent_add')
const agent_zhaopin = () => import('@/views/Agent/Agent_zhaopin/Agent_zhaopin')
const agent_power = () => import('@/views/Agent/Agent_power/Agent_power')

const jingli_list = () => import('@/views/Jingli/Jingli/Jingli_list')
const jingli_zhaopin = () => import('@/views/Jingli/Jingli/Jingli_zhaopin')

const spread_App = () => import('@/views/Spread/Spread_App/Spread_App')
const spread_coupon = () => import('@/views/Spread/Spread_coupon/Spread_coupon')
const coupon_add = () => import('@/views/Spread/Spread_coupon/Coupon_add')
const coupon_use = () => import('@/views/Spread/Spread_coupon/Coupon_use')
const spread_list = () => import('@/views/Spread/Spread_list/Spread_list')

const store_list = () => import('@/views/Store/Store_list/Store_list')
const store_add = () => import('@/views/Store/Store_list/Store_add')
const store_zhaopin = () => import('@/views/Store/Store_list/Store_zhaopin')

const tixian_list = () => import('@/views/Tixian_manage/Tixian_list')

const order_commentLabel = () => import('@/views/Order/Order_commentLabel/Order_commentLabel')
const order_commentManage = () => import('@/views/Order/Order_commentManage/Order_commentManage')
const order_list = () => import('@/views/Order/Order_list/Order_list')
const order_detail = () => import('@/views/Order/Order_list/Order_detail')
const order_refund = () => import('@/views/Order/Order_refund/Order_refund')
const refund_detail = () => import('@/views/Order/Order_refund/Refund_detail')
const order_tousu = () => import('@/views/Order/Order_tousu/Order_tousu')
const order_jspj = () => import('@/views/Order/Order_jspj/Order_jspj')

const admin_manage=()=>import('@/views/System/Admin_manage/Admin_manage')
const version_settings=()=>import('@/views/System/Version_settings/Version_settings')
const sys_settings=()=>import('@/views/System/Sys_settings/Sys_settings')
const other_settings=()=>import('@/views/System/Other_settings/Other_settings')
const role_manage=()=>import('@/views/System/Role_manage/Role_manage')
const login_log=()=>import('@/views/System/Login_log/Login_log')
const menu_manage=()=>import('@/views/System/Menu_manage/Menu_manage')
const settings_city_fenyong=()=>import('@/views/System/Other_settings/Settings_city_fenyong')
const dept_manage=()=>import('@/views/System/Dept_manage/Dept_manage')
const form_setting=()=>import('@/views/System/Form_setting/Form_setting')
const message_manage=()=>import('@/views/System/Message_manage/Message_manage')

const thing_setting = () => import('@/views/Thing/Setting/Setting')
const thing_goods = () => import('@/views/Thing/Goods/Goods_list')
const thing_goods_add = () => import('@/views/Thing/Goods/Goods_add')
const thing_goods_attr_setting = () => import('@/views/Thing/Goods/Goods_attr_setting')
const thing_category = () => import('@/views/Thing/Category/Category_list')
const thing_category_add = () => import('@/views/Thing/Category/Category_add')
const thing_attrtype = () => import('@/views/Thing/Attr/Attrtype_list')
const thing_attr = () => import('@/views/Thing/Attr/Attr_list')
const thing_attr_add = () => import('@/views/Thing/Attr/Attr_add')
const thing_order = () => import('@/views/Thing/Order/Order_list')
const thing_order_detail = () => import('@/views/Thing/Order/Order_detail')

const agent_tj=()=>import('@/views/DataStatistics/Agent_tj/Agent_tj')
const agent_tj_detail=()=>import('@/views/DataStatistics/Agent_tj/Agent_tj_detail')
const manager_tj=()=>import('@/views/DataStatistics/Manager_tj/Manager_tj')
const manager_tj_detail=()=>import('@/views/DataStatistics/Manager_tj/Manager_tj_detail')
const technician_tj=()=>import('@/views/DataStatistics/Technician/Technician')
const technician_tj_detail=()=>import('@/views/DataStatistics/Technician/Technician_tj_detail')
const user_tj=()=>import('@/views/DataStatistics/User/User')

const data_monitor=()=>import('@/views/Monitor/Data_monitor/Data_monitor')
const city_partner_list=()=>import('@/views/CityPartner/CityPartnerList')
const city_partner_zhaopin = ()=>import('@/views/CityPartner/CityPartner_zhaopin')
const citypartner_tj=()=>import('@/views/DataStatistics/Citypartner_tj/Citypartner_tj')
const city_partner_fy_setting=()=>import('@/views/CityPartner/Citypartner_fy_setting.vue')

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    meta:{
      title:'登录'
    }
  },
  {
    path: '/home',
    component: Home,
    meta:{
      title:'首页'
    },
    redirect: '/data_monitor',
    children: [
      {
        path: '/data_monitor',
        component: data_monitor,
        meta:{
          title:'数据监控'
        }
      },
      {
        path: '/welcome',
        component: Welcome,
        meta:{
          title:'首页'
        }
      },
      {
        path: '/service_add',
        component: service_add,
        meta:{
          title:'服务列表'
        }
      },
      {
        path: '/service_add/add',
        component: add,
      },
      {
        path: '/service_type',
        component: service_type,
        meta:{
          title:'服务类型'
        }
      },
      {
        path: '/service_banner',
        component: service_banner,
        meta:{
          title:'轮播图设置'
        }
      },
      {
        path: '/technician_level',
        component: technician_level,
        meta:{
          title:'技师等级'
        }
      },
      {
        path: '/technician_manage',
        component: technician_manage,
        meta:{
          title:'技师列表'
        }
      },
      {
        path: '/technician_manage/add',
        component: technician_manage_add
      },
      {
        path: '/technician_zhaopin',
        component: technician_zhaopin,
        meta:{
          title:'技师招聘'
        }
      },
      {
        path: '/technician_sect',
        component: technician_sect,
        meta:{
          title:'技师门派'
        }
      },
      {
        path: '/technician_yijian',
        component: technician_yijian,
        meta:{
          title:'问题反馈'
        }
      },
      {
        path: '/technician_verify',
        component: technician_verify,
        meta:{
          title:'信息审核'
        }
      },
      {
        path: '/vip_extension',
        component: vip_extension,
        meta:{
          title:'推广列表'
        }
      },
      {
        path: '/vip_level',
        component: vip_level,
        meta:{
          title:'会员等级'
        }
      },
      {
        path: '/user_list',
        component: user_list,
        meta:{
          title:'用户列表'
        }
      },
      {
        path: '/user_yijian',
        component: user_yijian,
        meta:{
          title:'意见反馈'
        }
      },
      {
        path: '/vip_list',
        component: vip_list,
        meta:{
          title:'会员列表'
        }
      },
      {
        path: '/vip_chongzhi',
        component: vip_chongzhi,
        meta:{
          title:'会员充值'
        }
      },
      {
        path: '/vip_warning',
        component: vip_warning,
        meta:{
          title:'会员预警'
        }
      },
      {
        path: '/agent_add',
        component: agent_add,
        meta:{
          title:'新增代理'
        }
      },
      {
        path: '/agent_zhaopin',
        component: agent_zhaopin,
        meta:{
          title:'代理招聘'
        }
      },
      {
        path: '/agent_power',
        component: agent_power,
        meta:{
          title:'代理权限设置'
        }
      },
      {
        path: '/jingli_list',
        component: jingli_list,
        meta:{
          title:'经理列表'
        }
      },
      {
        path: '/jingli_zhaopin',
        component: jingli_zhaopin,
        meta:{
          title:'经理招聘'
        }
      },
      {
        path: '/spread_App',
        component: spread_App,
        meta:{
          title:'APP推广'
        }
      },
      {
        path: '/spread_coupon',
        component: spread_coupon,
        meta:{
          title:'优惠券'
        }
      },
      {
        path: '/spread_coupon/coupon_add',
        component: coupon_add
      },
      {
        path: '/spread_coupon/coupon_use',
        component: coupon_use
      },
      {
        path: '/spread_list',
        component: spread_list,
        meta:{
          title:'推广标准'
        }
      },
      {
        path: '/store_list',
        component: store_list,
        meta:{
          title:'门店列表'
        }
      },
      {
        path: '/store_list/store_add',
        component: store_add,
      },
      {
        path: '/store_zhaopin',
        component: store_zhaopin,
        meta:{
          title:'门店招聘'
        }
      },
      {
        path: '/order_commentLabel',
        component: order_commentLabel,
        meta:{
          title:'评价标签'
        }
      },
      {
        path: '/order_commentManage',
        component: order_commentManage,
        meta:{
          title:'评价管理'
        }
      },
      {
        path: '/order_list',
        component: order_list,
        meta:{
          title:'订单列表'
        }
      },
      {
        path: '/order_list/order_detail',
        component: order_detail
      },
      {
        path: '/order_refund',
        component: order_refund,
        meta:{
          title:'退款'
        }
      },
      {
        path: '/order_tousu',
        component: order_tousu,
        meta:{
          title:'客户投诉'
        }
      },
      {
        path: '/order_jspj',
        component: order_jspj,
        meta:{
          title:'技师评价'
        }
      },
      {
        path: '/order_refund/refund_detail',
        component: refund_detail
      },
      {
        path: '/version_settings',
        component: version_settings,
        meta:{
          title:'版本管理'
        }
      },
      {
        path: '/sys_settings',
        component: sys_settings,
        meta:{
          title:'系统设置'
        }
      },
      {
        path: '/other_settings',
        component: other_settings,
        meta:{
          title:'城市管理'
        }
      },
      {
        path: '/admin_manage',
        component: admin_manage,
        meta:{
          title:'管理员'
        }
      },
      {
        path: '/role_manage',
        component: role_manage,
        meta:{
          title:'角色管理'
        }
      },
      {
        path: '/menu_manage',
        component: menu_manage,
        meta:{
          title:'菜单管理'
        }
      },
      {
        path: '/dept_manage',
        component: dept_manage,
        meta:{
          title:'部门管理'
        }
      },
      {
        path: '/login_log',
        component: login_log,
        meta:{
          title:'登录日志'
        }
      },
      {
        path: '/form_setting',
        component: form_setting,
        meta:{
          title:'调研表单'
        }
      },
      {
        path: '/message_manage',
        component: message_manage,
        meta:{
          title:'通知公告'
        }
      },
      {
        path: '/citypartner_tj',
        component: citypartner_tj,
        meta:{
          title:'城市合伙人'
        }
      },
      {
        path: '/agent_tj',
        component: agent_tj,
        meta:{
          title:'代理人'
        }
      },
      {
        path: '/agent_tj/agent_tj_detail',
        component: agent_tj_detail
      },
      {
        path: '/manager_tj',
        component: manager_tj,
        meta:{
          title:'经理人'
        }
      },
      {
        path: '/manager_tj/manager_tj_detail',
        component: manager_tj_detail,
      },
      {
        path: '/technician_tj',
        component: technician_tj,
        meta:{
          title:'技师'
        }
      },
      {
        path: '/technician/technician_tj_detail',
        component: technician_tj_detail,
      },
      {
        path: '/user_tj',
        component: user_tj,
        meta:{
          title:'用户'
        }
      },
      {
        path: '/other_settings/settings_city_fenyong',
        component: settings_city_fenyong
      },
      {
        path: '/thing_setting',
        component: thing_setting,
        meta:{
          title:'商城设置'
        }
      },
      {
        path: '/thing_goods',
        component: thing_goods,
        meta:{
          title:'商品列表'
        }
      },
      {
        path: '/thing_goods_add',
        component: thing_goods_add,
        meta:{
          title:'添加商品'
        }
      },
      {
        path: '/thing_goods_attr_setting',
        component: thing_goods_attr_setting,
        meta:{
          title:'商品属性设置'
        }
      },
      {
        path: '/thing_category',
        component: thing_category,
        meta:{
          title:'分类管理'
        }
      },
      {
        path: '/thing_category_add',
        component: thing_category_add,
        meta:{
          title:'添加分类'
        }
      },
      {
        path: '/thing_attrtype',
        component: thing_attrtype,
        meta:{
          title:'商品属性'
        }
      },
      {
        path: '/thing_attr',
        component: thing_attr,
        meta:{
          title:'商品属性'
        }
      },
      {
        path: '/thing_attr_add',
        component: thing_attr_add,
        meta:{
          title:'添加属性'
        }
      },
      {
        path: '/thing_order',
        component: thing_order,
        meta:{
          title:'订单管理'
        }
      },
      {
        path: '/thing_order_detail',
        component: thing_order_detail,
        meta:{
          title:'订单详情'
        }
      },
      {
        path: '/tixian_list',
        component: tixian_list,
        meta:{
          title:'提现管理'
        }
      },
      {
        path: '/city_partner_list',
        component: city_partner_list,
        meta:{
          title:'城市合伙人'
        }
      },
      {
        path: '/city_partner_fy_setting',
        component: city_partner_fy_setting,
        meta:{
          title:'分佣设置'
        }
      },
      {
        path: '/city_partner_zhaopin',
        component: city_partner_zhaopin,
        meta:{
          title:'城市合伙人招聘'
        }
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '后台管理'
  }
  next()
})
export default router
