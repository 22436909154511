<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增标准</el-button>
			</el-row>
			<!-- 技师等级表单 -->
			<el-table border :data="levelData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="biaozhun_id" label="ID"></el-table-column>
				<el-table-column prop="user_type_name" label="角色"></el-table-column>
				<el-table-column prop="biaozhun_name" label="标准名称">
          <template slot-scope="scope">
            <el-link type="primary" @click="showDetail(scope.row)">{{scope.row.biaozhun_name}}</el-link>
          </template>
        </el-table-column>
<!--        <el-table-column :show-overflow-tooltip="true" label="服务类型" width="220px">-->
<!--          <template slot-scope="scope">-->
<!--            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item}}&nbsp;&nbsp;</el-tag>-->
<!--          </template>-->
<!--        </el-table-column>-->
				<el-table-column label="操作" width="160px">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="800px" top="10vh" :visible.sync="addLevelDialog">
				<el-form ref="form" :model="form" label-width="120px" :rules="addlevelRules">
          <el-row class="fy-row">
            <el-col :span="24">
              <el-form-item label="角色" prop="user_type">
                <el-select v-model="form.user_type" placeholder="请选择角色" clearable style="width: 100%;" :disabled="form.biaozhun_id?true:false">
                  <el-option v-for="item in userTypeList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-form-item label="标准名称" prop="biaozhun_name">
                <el-input v-model="form.biaozhun_name" maxlength="10" placeholder="请输入标准名称" :disabled="isSee"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 发展技师 -->
          <el-row class="fy-row" v-if="form.user_type===2">
            <el-col :span="24" class="info">
              <div class="custom-item">发展技师</div>
            </el-col>
            <el-col :span="24">
              <el-form-item label="分成比例" prop="bfb">
                <el-input type="number" v-model="form.bfb" placeholder="请输入技师百分比" max="15" @input="numberChange(arguments[0], 15, 'bfb')" @change="numberChange(arguments[0], 15, 'bfb')" :disabled="isSee">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 发展技师 -->
          <el-row class="fy-row" v-if="form.user_type===1||form.user_type===3||form.user_type===4">
            <el-col :span="24" class="info">
              <div class="custom-item">发展技师</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="技师前" prop="num">
                <el-input type="number" v-model.number="form.num" placeholder="请输入技师次数" :disabled="isSee"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="10">
              <el-form-item label="" prop="bfb" label-width="10px">
                <el-input type="number" v-model="form.bfb" placeholder="请输入技师百分比" max="15" @input="numberChange(arguments[0], 15, 'bfb')" @change="numberChange(arguments[0], 15, 'bfb')" :disabled="isSee">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 发展客户 -->
          <el-row class="fy-row" v-if="form.user_type===1">
            <el-col :span="24" class="info">
              <div class="custom-item">发展客户</div>
            </el-col>
<!--            <el-col :span="24" style="margin-bottom: 10px;">-->
<!--              <el-button type="primary" @click="addAgent" icon="el-icon-plus" plain></el-button>-->
<!--            </el-col>-->
            <el-col :span="24">
              <el-row>
                <div class="custom-item">第一年</div>
                <el-col :span="12">
                  <el-form-item label="一级分佣比例" prop="bfb_1">
                    <el-input type="number" v-model="form.bfb_1" placeholder="请输入一级分佣比例" max="15" @input="numberChange(arguments[0], 15, 'bfb_1')" @change="numberChange(arguments[0], 15, 'bfb_1')" :disabled="isSee">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="二级分佣比例" prop="bfb_2">
                    <el-input type="number" v-model="form.bfb_2" placeholder="请输入二级分佣比例" max="80" @input="numberChange(arguments[0], 15, 'bfb_2')" @change="numberChange(arguments[0], 15, 'bfb_2')" :disabled="isSee">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <div class="custom-item">第二年</div>
                <el-col :span="12">
                  <el-form-item label="一级分佣比例" prop="bfb_1_y2">
                    <el-input type="number" v-model="form.bfb_1_y2" placeholder="请输入一级分佣比例" max="15" @input="numberChange(arguments[0], 15, 'bfb_1_y2')" @change="numberChange(arguments[0], 15, 'bfb_1_y2')" :disabled="isSee">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="二级分佣比例" prop="bfb_2_y2">
                    <el-input type="number" v-model="form.bfb_2_y2" placeholder="请输入二级分佣比例" max="80" @input="numberChange(arguments[0], 15, 'bfb_2_y2')" @change="numberChange(arguments[0], 15, 'bfb_2_y2')" :disabled="isSee">
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
<!--            <el-col :span="24">-->
<!--              <el-row v-for="(item, index) in agentBfb" :key="index">-->
<!--                <div class="custom-item">第{{ index+1 }}年</div>-->
<!--                <el-col :span="11">-->
<!--                  <el-form-item label="一级分佣比例" prop="bfb_1">-->
<!--                    <el-input type="number" v-model="item.bfb_1" placeholder="请输入一级分佣比例" max="15" @input="numberChange(arguments[0], 15, 'bfb_1')" @change="numberChange(arguments[0], 15, 'bfb_1')">-->
<!--                      <template #append>%</template>-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="11">-->
<!--                  <el-form-item label="二级分佣比例" prop="bfb_2">-->
<!--                    <el-input type="number" v-model="item.bfb_2" placeholder="请输入二级分佣比例" max="15" @input="numberChange(arguments[0], 15, 'bfb_2')" @change="numberChange(arguments[0], 15, 'bfb_2')">-->
<!--                      <template #append>%</template>-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col :span="2" class="flex-box" v-if="index>1">-->
<!--                  <el-button type="text" icon="el-icon-delete" @click="removeAgent(index)" plain></el-button>-->
<!--                </el-col>-->
<!--              </el-row>-->
<!--            </el-col>-->
          </el-row>

          <!-- 发展客户 -->
          <el-row class="fy-row" v-if="form.user_type===2||form.user_type===3||form.user_type===4">
            <el-col :span="24" class="info">
              <div class="custom-item">发展客户</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="客户前" prop="num_1">
                <el-input type="number" v-model.number="form.num_1" placeholder="请输入用户次数" :disabled="isSee"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="10">
              <el-form-item label="" prop="bfb_1" label-width="10px">
                <el-input type="number" v-model="form.bfb_1" placeholder="请输入用户百分比" max="15" @input="numberChange(arguments[0], 15, 'bfb_1')" @change="numberChange(arguments[0], 15, 'bfb_1')" :disabled="isSee">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

<!--          <el-form-item label="服务类型" prop="type_id">-->
<!--            <el-select v-model="form.type_id" placeholder="请选择服务类型" multiple clearable style="width: 100%;">-->
<!--              <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	import {serviceTypeAll} from "@/api/request/service";

  let _this;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增标准",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				form: {
          biaozhun_id: null,
          user_type: null,
					biaozhun_name: '',
					num: '',
					bfb: '',
					num_1: '',
					bfb_1: '',
          bfb_2: '',
          bfb_1_y2: '',
          bfb_2_y2: '',
          type_id: null
				},
        agentBfb:[
            {bfb_1: '', bfb_2: ''},
            {bfb_1: '', bfb_2: ''},
        ],
				addlevelRules: {
          user_type: [{required: true, message: "请选择角色", trigger: "blur"}, ],
					biaozhun_name: [{required: true, message: "请输入标准名称", trigger: "blur"}, ],
					num: [{required: true, message: "请输入技师次数", trigger: "blur"}, {type: "number", message: "请输入数字"},],
					bfb: [{required: true, message: "请输入技师百分比", trigger: "blur"}, {type: "number", message: "请输入数字"},],
					num_1: [{required: true, message: "请输入用户次数", trigger: "blur"}, {type: "number", message: "请输入数字"},],
					bfb_1: [{required: true, message: "请输入分成百分比", trigger: "blur"}, {type: "number", message: "请输入数字"},],
          bfb_2: [{required: true, message: "请输入二级百分比", trigger: "blur"}, {type: "number", message: "请输入数字"},],
          bfb_1_y2: [{required: true, message: "请输入一级百分比", trigger: "blur"}, {type: "number", message: "请输入数字"},],
          bfb_2_y2: [{required: true, message: "请输入二级百分比", trigger: "blur"}, {type: "number", message: "请输入数字"},],
				},
				levelData: [],
        typeList: [],
        userTypeList: [
          {id: 1, name:"渠道推广员"},
          {id: 2, name:"经纪人"},
          {id: 3, name:"技师"},
          {id: 4, name:"门店"},
        ],
        isSee: false
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				_this = this;
				this.getBiaozhunList();
				this.getServiceType();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
			getBiaozhunList() {
				var url = 'biaozhun/_list';
				let params = {
					biaozhun_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						_this.total = res.total;
						_this.levelData = res.list;
					} else {
						_this.$message.error(res.msg);
					}
				}).catch((err) => {
					_this.$message.error('网络错误');
				});
			},
			//新增
			addLevel() {
        this.isSee = false
				this.title = "新增标准";
				this.form = {
          biaozhun_id: '',
					biaozhun_name: '',
					num: '',
					bfb: '',
					num_1: '',
					bfb_1: '',
				};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
        console.log("【提交表单】",this.form);
				this.$refs.form.validate(valid => {
					if(!valid) {
						return;
					} else {
            console.log("【提交表单信息】");
            // return;
						if(timer != null) {
							clearTimeout(timer);
						}
						timer = setTimeout(() => {
							var url = 'biaozhun/addOrUpdate';
							let params = this.form;
							_this.fd_post(url, params).then((res) => {
								if(res.status) {
									_this.addLevelDialog = false;
									_this.$refs.form.resetFields();
									_this.$message.success('操作成功');
									_this.getBiaozhunList();
								} else {
									_this.$message.error(res.msg);
								}
							}).catch((err) => {
								_this.$message.error('网络错误');
							});
						}, 200);
					}
				});
			},
      showDetail(levelInfo) {
        this.isSee = true
        this.title = "查看标准";
        this.addLevelDialog = true
        for(let k in levelInfo) {
          this.form[k] = levelInfo[k]
        }
      },
			//打开编辑对话框
			editLevel(levelInfo) {
        this.isSee = false
				this.title = "编辑标准";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.form[k] = levelInfo[k]
				}
			},
			//删除
			removeLevel(item) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'biaozhun/_del';
					let params = {
						biaozhun_id: item.biaozhun_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getBiaozhunList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getBiaozhunList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getBiaozhunList();
			},
			numberChange(val, maxNum, name) {
				//转换数字类型
				this.form[name] = Number(val)
				//重新渲染
				this.$nextTick(() => {
					//比较输入的值和最大值，返回小的
					let num = Math.min(Number(val), maxNum)
					//输入负值的情况下， = 0（可根据实际需求更该）
					if(num < 0) {
						this.form[name] = 0
					} else {
						//反之
						this.form[name] = num
					}
				})
			},
      addAgent() {
        this.agentBfb.push({bfb_1: '', bfb_2: ''})
      },
      removeAgent(index) {
        this.agentBfb.splice(index,1)
      }
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
  .fy-row{
    margin-bottom: 0px;
  }
  .flex-box{
    display: flex;
    align-items: center;
    height: 38px;
    padding-left: 10px;
  }
  .warning{
    height: 60px;
    padding-left: 20px;
    border-radius: 3px;
    background-color: #FFF6F7;
    border-left: 5px solid #FE6C6F;
    p{
      color: #666;
      line-height: 60px;
      text-align:left;
      font-size: 14px;
    }
  }
  .info {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
    text-align: left;
    .el-col{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>