<template>
  <div>
    <el-tag style="cursor: pointer;" @click="initUser">
      <div v-if="wxUserInfo.nickName">{{wxUserInfo.nickName}}&nbsp;&nbsp;&nbsp;&nbsp;{{wxUserInfo.phoneNumber}}</div>
      <div v-else>选择关联用户</div>
    </el-tag>
    <el-dialog :append-to-body="true" @close="closeUserVisible" title="关联用户" :visible.sync="userVisible" width="800px" top="5vh" center>
      <el-form :model="queryParams" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-input v-model="queryParams.userInfo" placeholder="请输入用户昵称/手机号" clearable @keyup.enter.native="searchUser"></el-input>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" style="margin-left:10px" @click="searchUser">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table highlight-current-row @row-click="rowClick" :data="relevantUserTable" class="wx-table" height="calc(100vh - 380px)" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column prop="wx_id" label="用户ID"></el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <div>
              <img class="header-img" :src="scope.row.avatarUrl" alt="" v-if="scope.row.avatarUrl">
              <img class="header-img" src="@/assets/img/default_headimg.jpg" alt="" v-else>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="昵称"></el-table-column>
        <el-table-column prop="phoneNumber" label="手机号"></el-table-column>
      </el-table>
      <Pagination :queryInfo="queryUserInfo" :total="user_total" @handleSizeChange="handleUserSizeChange" @handleCurrentChange="handleUserCurrentChange"></Pagination>
      <div style="text-align:center">
        <el-button type="default" @click="closeUserVisible">取消</el-button>
        <el-button type="primary" @click="subUser">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  name:'WxUserSelect',
  props:{
    wxInfo:{
      type:Object,
      default(){
        return {}
      }
    },
  },
  components: {
    Pagination
  },
  data(){
    return{
      wxUserInfo: this.wxInfo,
      userVisible:false,
      queryParams: {
        userInfo:'',
      },
      queryUserInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      relevantUserTable:[],
      user_total:0
    }
  },
  watch:{
    wxInfo(newVal,oldVal){
      // console.log(newVal)
      this.wxUserInfo = newVal;
    }
  },
  methods: {
    initUser() {
      this.userVisible = true;
      this.getCustomList();
    },
    getCustomList() {
      var url = 'custom/user_list';
      let params = {
        is_phone: 1,
        nickName: this.queryParams.userInfo,
        size: this.queryUserInfo.pagesize,
        page: this.queryUserInfo.pagenum,
      };
      this.fd_post(url, params).then((res) => {
        if(res.status) {
          this.user_total = res.total;
          this.relevantUserTable = res.list;
        } else {
          this.$message.error(res.msg);
        }
      }).catch((err) => {
        this.$message.error('网络错误');
      });
    },
    closeUserVisible() {
      this.userVisible = false;
      this.queryParams.userInfo = '';
      this.relevantUserfalse = {};
    },
    searchUser() {
      this.queryUserInfo.pagenum = 1
      this.getCustomList();
    },
    rowClick(row) {
      this.relevantUserfalse = row;
    },
    //关联用户
    subUser() {
      if (!this.relevantUserfalse.wx_id) return this.$message.error('请选择关联用户');
      this.wxUserInfo = this.relevantUserfalse;
      this.userVisible = false;
      this.$emit('change',this.wxUserInfo)
    },
    //页数改变
    handleUserSizeChange(newSize) {
      this.queryUserInfo.pagenum = 1;
      this.queryUserInfo.pagesize = newSize;
      this.getCustomList();
    },
    //页码改变
    handleUserCurrentChange(newPage) {
      this.queryUserInfo.pagenum = newPage;
      this.getCustomList();
    },
  },
}
</script>

<style lang="scss" scoped>
.wx-table{
  width: 100%;
  margin-top:15px;
  //height: calc(100vh - 380px);
  overflow-y: scroll;
}
.header-img{
  width:60px;
  height:60px;
  border-radius: 7px;
  overflow: hidden;
}
</style>