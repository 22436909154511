<template>
	<div>
		<el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="姓名:">
              <el-input v-model="queryForm.name" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
            <el-form-item label="手机号:">
              <el-input v-model="queryForm.phone" placeholder="请输入手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="服务类型">
              <el-select v-model="queryForm.type_id" placeholder="请选择服务类型" clearable width="100%" style="display: block;">
                <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>
              </el-select>
            </el-form-item>
            <el-form-item label="入驻状态">
              <el-select v-model="queryForm.entry_status" placeholder="请选择入驻状态" clearable width="100%" style="display: block;">
                <el-option v-for="item in entryStatusList" :key="item.value" :label="item.name" :value="item.value"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
              <el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
			<el-row>
				<el-button type="primary" @click="addLevel">新增渠道推广员</el-button>
			</el-row>
			<!-- 渠道推广员表单 -->
			<el-table :data="levelData" border style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="agent_id" label="ID"></el-table-column>
				<el-table-column prop="real_name" label="真实名称"></el-table-column>
<!--				<el-table-column prop="account" label="账号"></el-table-column>-->
				<el-table-column prop="phoneNumber" label="手机号" width="120px"></el-table-column>
				<el-table-column label="上岗状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.agent_status" @change="setAgentStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="关联用户">
					<template slot-scope="scope">
				        <el-tag type="primary" disable-transitions>{{scope.row.relevantUser.nickName}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column prop="parent_name" label="上级渠道推广员"></el-table-column>
				<el-table-column prop="city_name" label="服务区域"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="服务类型" width="200">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.type_list" :key="index" style="margin-left: 5px;">{{item.type_name}}-{{item.career}}&nbsp;&nbsp;</el-tag>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="入驻状态" width="180">
          <template slot-scope="scope">
            <el-tag type="success" disable-transitions v-if="scope.row.is_entry">已入驻</el-tag>
            <el-tag type="danger" disable-transitions v-else>未入驻</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="entry_time" label="入驻时间" :show-overflow-tooltip="true" width="150"></el-table-column>
				<el-table-column prop="add_time" label="注册时间" width="150px"></el-table-column>
				<el-table-column label="操作" fixed="right" width="260px">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<!--<el-button type="danger" plain size="small" @click="removeLevel(scope.row)">删除</el-button>-->
						<el-button size="mini" type="success" plain @click="setPwd(scope.row)">重置密码</el-button>
            <el-button size="mini" type="warning" plain @click="changeBz(scope.row)" v-if="!scope.row.parent_id">分佣标准</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增/编辑对话框 -->
			<el-dialog :title="title" width="600px" top="5vh" :visible.sync="addLevelDialog" :modal-append-to-body="false">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="120px" :rules="addlevelRules">
					<el-row>
            <el-col :span="22">
              <el-form-item label="关联用户" prop="relevantUser" style="text-align: left;">
                <WxUserSelect :wxInfo="addlevelForm.relevantUser" @change="handleWxInfoChange"></WxUserSelect>
              </el-form-item>
            </el-col>
            <el-col :span="22">
							<el-form-item label="真实名称" prop="real_name">
								<el-input v-model="addlevelForm.real_name" maxlength="10" placeholder="请输入真实姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="22">
							<el-form-item label="上级渠道推广员" prop="parent_id" style="text-align: left;">
								<el-select v-model="addlevelForm.parent_id" clearable filterable remote reserve-keyword placeholder="输入名称/手机号进行搜索"
										:remote-method="remoteMethod" :loading="loading" style="width: 100%;">
								    <el-option
								      v-for="(item,key) in options"
								      :key="item.agent_id"
								      :label="item.real_name"
								      :value="item.agent_id">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
            <el-col :span="22">
              <el-form-item label="手机号" prop="phoneNumber">
                <el-input v-model.number="addlevelForm.phoneNumber" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="服务区域" prop="city_id">
                <treeselect v-model="addlevelForm.city_id" :options="city_list" :normalizer="normalizer"
                            :defaultExpandLevel="Infinity" :show-count="true" placeholder="请选择服务区域" />
              </el-form-item>
            </el-col>
<!--						<el-col :span="22">-->
<!--							<el-form-item label="账号" prop="account">-->
<!--								<el-input v-model="addlevelForm.account" maxlength="10" placeholder="请输入账号" :disabled="new_account?'disabled':false"></el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
<!--						<el-col :span="22">-->
<!--							<el-form-item label="密码" prop="password">-->
<!--								<el-input v-model="addlevelForm.password" placeholder="请输入密码" :disabled="new_account?'disabled':false" style="width:220px"></el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
            <el-col :span="22">
              <el-form-item label="服务类型:" prop="type_id">
                <el-select v-model="addlevelForm.type_id" placeholder="请选择服务类型" multiple clearable style="width: 100%;">
                  <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id" />
                </el-select>
              </el-form-item>
            </el-col>
<!--						<el-col :span="22">-->
<!--							<el-form-item label="第二年分佣配比" prop="fypb_two" style="text-align: left;">-->
<!--								<el-input v-model.number="addlevelForm.fypb_two" placeholder="请输入第二年分佣配比" style="width: 100%;">-->
<!--                  <template #append>%</template>-->
<!--                </el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
<!--						<el-col :span="22">-->
<!--							<el-form-item label="第三年分佣配比" prop="fypb_three">-->
<!--								<el-input v-model.number="addlevelForm.fypb_three" placeholder="请输入第三年分佣配比" style="width: 100%;">-->
<!--                  <template #append>%</template>-->
<!--                </el-input>-->
<!--							</el-form-item>-->
<!--						</el-col>-->
						<el-col :span="24" style="text-align:center">
						  	<el-button type="default" @click="addLevelDialog=false">取消</el-button>
							<el-button type="primary" @click="editNewLevel">确定</el-button>
					  	</el-col>
					</el-row>
				</el-form>
			</el-dialog>

      <el-dialog title="修改分佣标准" width="800px" :visible.sync="bzVisible">
        <el-form label-width="120px">
          <el-tabs v-model="activeName" @tab-click="handleBiaozhunClick" style="margin-bottom: 0px;">
            <el-tab-pane v-for="(item,index) in biaozhunTypeList" :label="item.type_name" :name="'type@'+item.type_id" style="margin-top: 15px;">
              <el-form-item label="分佣标准:">
                <el-select v-model="item.biaozhun_id" placeholder="请选择" style="width: 100%;" clearable @change="changeBiaozhun">
                  <el-option
                      v-for="item in biaozhun_list"
                      :key="item.biaozhun_id"
                      :label="item.biaozhun_name"
                      :value="item.biaozhun_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <!-- 发展技师 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展技师</div>
            </el-col>
            <el-col :span="11">
              <el-form-item label="技师前" prop="num">
                <el-input type="number" v-model.number="biaozhunShow.num" placeholder="请输入技师次数" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex-box">
              笔，分成比例
            </el-col>
            <el-col :span="8">
              <el-form-item label="" prop="bfb" label-width="10px">
                <el-input type="number" v-model="biaozhunShow.bfb" placeholder="请输入技师百分比" disabled>
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 发展客户 -->
          <el-row class="fy-row">
            <el-col :span="24" class="info">
              <div class="custom-item">发展客户</div>
            </el-col>
            <el-col :span="24">
              <el-row>
                <div class="custom-item">第一年</div>
                <el-col :span="12">
                  <el-form-item label="一级分佣比例" prop="bfb_1">
                    <el-input type="number" v-model="biaozhunShow.bfb_1" placeholder="请输入一级分佣比例" disabled>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="二级分佣比例" prop="bfb_2">
                    <el-input type="number" v-model="biaozhunShow.bfb_2" placeholder="请输入二级分佣比例" disabled>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <div class="custom-item">第二年</div>
                <el-col :span="12">
                  <el-form-item label="一级分佣比例" prop="bfb_1_y2">
                    <el-input type="number" v-model="biaozhunShow.bfb_1_y2" placeholder="请输入一级分佣比例" disabled>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="二级分佣比例" prop="bfb_2_y2">
                    <el-input type="number" v-model="biaozhunShow.bfb_2_y2" placeholder="请输入二级分佣比例" disabled>
                      <template #append>%</template>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-button type="default" @click="bzVisible=false">取消</el-button>
          <el-button type="primary" @click="subBzEdit">确认</el-button>
        </el-form>
      </el-dialog>

		</el-card>
	</div>
</template>

<script>
	import WxUserSelect from "@/components/Form/WxUserSelect.vue";

  let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
  import {biaozhunAll, serviceTypeAll, setUserBiaozhun} from "@/api/request/service";

	export default {
		components: {
      WxUserSelect,
			Treeselect,Pagination
		},
		data() {
			return {
				title: "新增渠道推广员",
        queryForm: {
          name: '',
          phone: '',
          type_id: '',
          entry_status: '',
        },
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				options: [],
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
					real_name: '',
					// account: '',
					// password: '',
					parent_id: '',
					fypb_two: '',
					fypb_three: '',
					city_id: null,
					relevantUser: {},
					phoneNumber: '',
          type_id: null
				},
				addlevelRules: {
					real_name: [{
						required: true,
						message: "请输入真实名称",
						trigger: "blur"
					}, ],
					account: [{
						required: true,
						message: "请输入账号",
						trigger: "blur"
					}, ],
					phoneNumber: [{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择服务区域",
						trigger: "blur"
					}, ],
          type_id: [{required: true, message: '请选择服务类型', trigger: 'blur'}],
          relevantUser: [{required: true, message: '请选择关联用户', trigger: 'blur'},
            { validator: (rule, value, callback) => {
                if (!value||!value.wx_id) return callback(new Error('请选择关联用户'));
                callback();
              }, trigger: 'blur'}],
				},
				levelData: [],
				userVisible: false,
				userForm: {
					userInfo: ''
				},
				relevantUserTable: [],
				queryUserInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				// new_account: '',
				user_total: 0,
				relevantUserfalse: {},
				loading: false,
				city_list: [],
        typeList: [],
        entryStatusList:[
          {name: '已入驻',value: 1},
          {name: '未入驻',value: 2}
        ],
        //分佣标准
        bzVisible: false,
        biaozhun_id: '',
        activeName: '',
        biaozhunForm: {
          relation_id: '',
          user_type: 1,
          biaozhun: ""
        },
        biaozhun_list: [],
        biaozhunTypeList: [],
        biaozhunShow: {},
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCityList();
				this.getAgentList();
        this.getServiceType();
        this.getBiaozhunList();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
      async getBiaozhunList() {
        const res = await biaozhunAll({user_type: 1})
        console.log(res)
        if (res.status) {
          this.biaozhun_list = res.data;
        } else {
          this.$message.error(res.msg);
        }
      },
			getCityList(){
        var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
      },
			remoteMethod(query) {
				if (query !== '') {
					that.loading = true;
					setTimeout(() => {
            that.loading = false;
            var url = 'agent/parent_agent';
						let params = {
							agent_name: query,
							agent_id: that.addlevelForm.agent_id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status){
								that.options = res.list;
								console.log(res.list);
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					}, 200);
				} else {
					this.options = [];
				}
			},
			getAgentList() {
				let that = this;
				var url = 'agent/agent_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
          ...this.queryForm
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.levelData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
      //查询
      search() {
        this.queryInfo.pagenum = 1;
        this.getAgentList();
      },
      // 重置
      reset() {
        this.queryInfo.pagenum = 1;
        this.getAgentList();
      },
			//转换菜单数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//新增渠道推广员
			addLevel() {
				this.title = "新增渠道推广员";
				this.addLevelDialog = true;
				this.addlevelForm = {
					real_name: '',
					// account: '',
					// password: '',
					parent_id: '',
					fypb_two: '',
					fypb_three: '',
					relevantUser: {},
					city_id: null,
					phoneNumber: ''
				};
				// this.new_account = "";
			},
			//确认新增
			editNewLevel() {
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'agent/addOrUpdate';
							if(that.addlevelForm.relevantUser.wx_id) {
								that.addlevelForm.wx_id = that.addlevelForm.relevantUser.wx_id;
							}
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getAgentList();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.addLevelDialog = false;
								that.$message.error('网络错误');
							});
						},300);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑渠道推广员";
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k];
				}
        // console.log("【用户信息】",this.addlevelForm.relevantUser)
				if(this.addlevelForm.parent_id){
					this.remoteMethod(this.addlevelForm.parent_name);
				}
				setTimeout(() => {
					// this.new_account = this.addlevelForm.account;
					this.addLevelDialog = true;
				},300);
			},
			//设置上岗状态
			setAgentStatus(item){
				if(timer != null){clearTimeout(timer);}
				timer = setTimeout(() => {
					var url = 'agent/setAgentStatus';
          let params = {
            agent_id: item.agent_id
          };
					that.fd_post(url, params).then((res) => {
            if(res.status){
              that.$message.success("操作成功");
            }else{
              that.$message.error(res.msg);
            }
          }).catch((err) => {
              that.$message.error('网络错误');
          });
				},300);
			},
			//删除
			removeLevel(item) {
				this.$confirm('删除之后，该渠道推广员账号下的所有技师将不再属于该渠道推广员，技师归属总部，渠道推广员的佣金提成也会一并取消。确认删除渠道推广员账号吗？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'agent/deleteAgent';
					let params = {
						agent_id: item.agent_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
							this.getAgentList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除')
				});
			},
			setPwd(item){
				this.$confirm('您确定要为'+item.real_name+'【'+item.phoneNumber+'】重置密码吗，确定后密码将会重置为【123456】？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'agent/updatePwd';
					let params = {
						agent_id: item.agent_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('密码重置成功');
							this.getAgentList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消重置')
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getAgentList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getAgentList();
			},
      handleWxInfoChange(e) {
        this.addlevelForm.relevantUser = e;
        if (e.phoneNumber) {
          this.addlevelForm.phoneNumber = e.phoneNumber;
        }
      },
      //分佣标准
      changeBz(item){
        this.biaozhunForm.relation_id = item.agent_id;
        this.biaozhunForm.biaozhun = "";
        this.activeName = ""
        console.log(item.type_list)
        this.biaozhunTypeList = item.type_list.map(item => {
          return {...item, biaozhun_id: item.biaozhun_id?item.biaozhun_id:""}
        });
        if (this.biaozhunTypeList&&this.biaozhunTypeList.length>0) {
          this.activeName = 'type@'+this.biaozhunTypeList[0].type_id
          if (this.biaozhunTypeList[0].biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === this.biaozhunTypeList[0].biaozhun_id)
          }
        }
        this.bzVisible = true;
      },
      changeBiaozhun(biaozhun_id){
        this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === biaozhun_id)
      },
      async subBzEdit(){
        const biaozhun = this.biaozhunTypeList.map(item => {
          return {
            type_id: item.type_id,
            biaozhun_id: item.biaozhun_id
          }
        })
        this.biaozhunForm.biaozhun = JSON.stringify(biaozhun)
        console.log("【设置标准】",this.biaozhunForm);
        const res = await setUserBiaozhun(this.biaozhunForm).catch((err) => {
          this.$message.error('网络错误');
        })
        if (res.status === 1) {
          this.bzVisible = false;
          this.$message.success("操作成功");
          this.getAgentList();
        }else{
          this.$message.error(res.msg);
        }
      },
      handleBiaozhunClick(tab, event) {
        if (tab.paneName.indexOf('type@')>-1) {
          const type_id = parseInt(tab.paneName.split('@')[1])
          const bty = this.biaozhunTypeList.find(item => item.type_id === type_id)
          console.log(type_id, bty)
          if (bty.biaozhun_id) {
            this.biaozhunShow = this.biaozhun_list.find(item => item.biaozhun_id === bty.biaozhun_id)
          }
        }
      },
		},
	}
</script>

<style lang="scss" scoped>
	.el-row {
		text-align: left;
		margin-bottom: 20px;
	}
	.mr3{
	  margin-right: 5px;
	}
  ::v-deep .vue-treeselect--single .vue-treeselect__input {
    display: block;
  }
  .fy-row{
    margin-bottom: 0px;
  }
  .flex-box{
    display: flex;
    align-items: center;
    height: 38px;
    padding-left: 10px;
  }
  .warning{
    height: 60px;
    padding-left: 20px;
    border-radius: 3px;
    background-color: #FFF6F7;
    border-left: 5px solid #FE6C6F;
    p{
      color: #666;
      line-height: 60px;
      text-align:left;
      font-size: 14px;
    }
  }
  .info {
    padding: 8px 16px;
    background-color: #ecf8ff;
    border-radius: 4px;
    border-left: 5px solid #50bfff;
    margin: 20px 0;
    text-align: left;
    .el-col{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>