<template>
  <div class="upload-file">
    <el-upload
      multiple
      action="#"
      list-type="picture-card"
      :before-upload="handleBeforeUpload"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      :on-remove="handleDelete"
      :show-file-list="true"
      :headers="headers"
      :http-request="handleUpload"
      class="upload-file-uploader"
      ref="fileUploadRef"
      :file-list="fileList"
      :class="{ hide: fileList.length >= limit }"
    >
      <!-- 上传按钮 -->
      <i class="el-icon-plus"></i>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">大小不超过 <b style="color: #f56c6c">{{ fileSize < 1 ? (fileSize*1000 + "KB") : (fileSize + "MB") }}</b></template>
      <template v-if="fileType">格式为 <b style="color: #f56c6c">{{ fileType.join("/") }}</b></template>的文件
    </div>
    <!-- 文件列表 -->
    <el-dialog v-model="dialogVisible" title="预览" width="800px" append-to-body>
      <img :src="dialogImageUrl" style="display: block; max-width: 100%; margin: 0 auto" />
    </el-dialog>
  </div>
</template>

<script>
import { createUuid,handleOssUploadPath } from "@/common/js/utils"
import { baseUrl,upUrl } from '@/api/config.js'
import axios from 'axios'
export default {
  name:'ImageUpload',
  props:{
    modelValue: [String, Object, Array],
    // 数量限制
    limit: {
        type: Number,
        default: 60,
    },
    // 大小限制(MB)
    fileSize: {
        type: Number,
        default: 300,
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
        type: Array,
        default: () => ["doc", "docx", "xls", "xlsx", "ppt", "txt", "pdf"],
    },
    // 是否显示提示
    isShowTip: {
        type: Boolean,
        default: true
    },
    autoUpload: {
        type: Boolean,
        default: true
    },
    savePath: {
        type: String,
        default: ''
    },
    isCreateName: {
        type: Boolean,
        default: true
    },
    single: {
      type: Boolean,
      default: true
    }
	},
  model: {
    prop: "modelValue",
    event: "modelChange",
  },
  data () {
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      number: 0,
			uploadList: [],
			fileList: [],
			headers: {},
      isModel: true
    }
  },
	computed: {
		showTip() {
			return this.isShowTip && (this.fileType || this.fileSize)
		}
	},
  watch:{
   modelValue(val){
     console.log("【上传modelValue】",this.isModel)
     this.initImage(val)
   }
  },
  created() {
    console.log("【上传modelValue】",this.isModel,this.modelValue)
    this.initImage(this.modelValue)
  },
  mounted() {},
  methods:{
    initImage(val) {
      if (!this.isModel) {
        return
      }
      if (this.single) {
        if (val) {
          this.fileList = [{name: this.getFileName(val), url: val, percentage: 100}];
        } else {
          this.fileList = []
        }
      } else {
        if (val) {
          let temp = 1;
          // 首先将值转为数组
          let list = [];
          if (Array.isArray(val)) {
            list = val;
          }
          // 然后将数组转为对象数组
          this.fileList = list.map(item => {
            item = {name: item.name, url: item.url, percentage: item.percentage};
            item.uid = item.uid || new Date().getTime() + temp++;
            return item;
          });
        } else {
          this.fileList = [];
          return [];
        }
      }
    },
  	handleBeforeUpload(file) {
  	    console.log(file)
        this.isModel = false
  	    // 校检文件类型
  	    if (this.fileType.length) {
  	        const fileName = file.name.split('.');
  	        const fileExt = fileName[fileName.length - 1];
  	        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
  	        if (!isTypeOk) {
  	            this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
  	            return false;
  	        }
  	    }
  	    // 校检文件大小
  	    if (this.fileSize) {
  	        const isLt = file.size / 1024 / 1024 < this.fileSize;
  	        if (!isLt) {
  	            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
  	            return false;
  	        }
  	    }
  	    console.log(this.fileList, "fileList");
  	    if (this.fileList.length < this.limit) {
  	      this.number++;
          let img = new Image();
          img.src = URL.createObjectURL(file);
          let item = {
            uid: file.uid,
            name: file.name,
            url: img.src,
            file: file,
            percentage: 0
          };
          this.fileList.push(item);
          console.log("【fileList】", this.fileList);
  	      if (this.autoUpload) {
  	        this.handleUploadSubmit().then((res) => {
              console.log("【handleUploadSubmit】", res);
              this.emitValue()
              if (res.errno) {
                this.$message.error("上传失败");
              }
              this.isModel = true
  	        }).catch(err => {
              this.$message.error("上传失败");
              if (this.single) {
                this.fileList = []
              }
              this.isModel = true
            })
  	      }
  	      return true;
  	    } else {
  	      this.handleExceed()
  	    }
  	    return false;
  	},
		handleExceed() {
		    this.$message.error(`上传文件数量不能超过 ${this.limit} 个!`);
		},
		handleUploadError() {
      this.$message.error(`上传文件失败`);
		},
		handleDelete(index) {
      console.log("【handleDelete】", index);
      this.fileList.splice(index, 1);
      this.emitValue()
		},
    emitValue() {
      if (this.single) {
        this.$emit("modelChange", this.fileList.length >0 ? this.fileList[0].url : "");
      } else {
        this.$emit("modelChange", this.fileList);
      }
    },
		getFileName(name) {
		    // 如果是url那么取最后的名字 如果不是直接返回
		    if (name.lastIndexOf("/") > -1) {
		        return name.slice(name.lastIndexOf("/") + 1);
		    } else {
		        return name;
		    }
		},
		handleUploadSubmit(){
		    console.log(this.fileList, "fileList");
		    return new Promise((pResolve, pReject) => {
		        if (this.fileList.length > 0) {
		            console.log(this.fileList, "fileList");
								let date = new Date();
								let path = date.getFullYear()+ "" + (date.getMonth() + 1)+ "" + date.getDay();
								console.log("【路径】", path);
								console.log("【访问地址】",baseUrl,  "index/uploadSetting");
								axios({
									url: "upload/uploadSetting",
									headers: {
										"Content-Type": "multipart/form-data"
									},
									method: "post",
									data: {
										path: this.savePath+"/"+path+"/"
									},
								}).then(res => {
									const signResponse = res.data
									console.log("【上传配置信息】", signResponse);
									let promiseArr = [];
									this.fileList.forEach((item, index) => {
										let p = new Promise((resolve, reject) => {
											let fileName = item.name.replace(/ /g, '').replace(/#/g, '').replace(/\//g, '');
											if (this.isCreateName) {
												let filenames = item.name.split(".");
												fileName = createUuid(16, 16)+"."+filenames[filenames.length-1];
											}
											if(this.savePath) {
												name = this.savePath;
											}
											const dirPath = `${upUrl}/${signResponse.prefix}${fileName}`
											console.log("【上传信息路径】", `${signResponse.prefix}${fileName}`);
											console.log("【上传完整路径】", dirPath);
											
											var formData = new FormData();
											formData.append("Filename", fileName);
											formData.append("key", `${signResponse.prefix}${fileName}`);
											formData.append("policy", signResponse.policy);
											formData.append("OSSAccessKeyId", signResponse.OSSAccessKeyId);
											formData.append("success_action_status", '200');
											formData.append("signature", signResponse.signature);
											formData.append("file", item.file);
											
											axios({
												url: upUrl,
												headers: {
													"Content-Type": "multipart/form-data",
													"Cache-Control": "no-cache",
													"Content-Encoding": "utf-8",
													"Content-Length": item.file.size
												},
												method: "post",
												data: formData,
												// 监听上传进度
												onUploadProgress: progressEvent => {
													const { loaded, total } = progressEvent;
													const percent = Math.round((loaded * 100) / total);
													console.log(`上传进度: ${percent}%`);
													// 在这里可以实现你的进度条更新逻辑
													this.fileList[index].percentage = percent
												},
											}).then(upload => {
												console.log("【上传结果】", upload);
												this.fileList[index].url = dirPath;
												resolve({code: 200, msg: "上传成功"})
											})
											.catch(error => {
												reject({code: 1, msg: "上传失败"})
                        this.fileList.splice(index, 1)
											})
										});
										promiseArr.push(p);
									})
									//等待图片上传完成
									Promise.allSettled(promiseArr).then(async (result) => {
											console.log("【全部上传完成】", result);
											this.fileList.forEach(item=>{
												let params = {
													url: item.url,
													name: item.file.name,
													size: item.file.size
												};
												this.uploadList.push(params);
											});
											console.log(this.uploadList, "uploadList");
											pResolve({"errno": 0, "imgList": this.uploadList});
									}).catch(function (err) {
									  pReject({"errno": 1, "message": "文件上传失败"});
									});
								}).catch(error => {
									pReject({"errno": 1, "message": "文件上传失败"});
								})
		        } else {
		          pReject({"errno": 1,"message": "请至少上传一个文件"});
		        }
		    });
		},
		handleUpload () {
		
		}
  }
}
</script>

<style scoped lang="scss">
.upload-file-uploader {
  margin-bottom: 5px;
}
.upload-file-list .el-upload-list__item {
  border: 1px solid #e4e7ed;
  line-height: 2;
  margin-bottom: 10px;
  position: relative;
}
.upload-file-list .ele-upload-list__item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
}
.ele-upload-list__item-content-action .el-link {
  margin-right: 10px;
}
</style>
