<template>
  <div class="tabs">
    <div class="item" :class="{'active': city_id===item.city_id}" type="primary" @click="typeChange(item)" v-for="item in list" :key="item.city_id">{{ item.city_name }}</div>
  </div>
</template>

<script>
export default {
  name:'CityScroll',
  props:{
    list:{
      type: Array,
      default(){
        return []
      }
    },
    id:{
      type: Number,
      default(){
        return 0
      }
    },
  },
  data(){
    return {
      city_id: 0
    }
  },
  created() {
    this.city_id = this.id
  },
  methods: {
    typeChange(e) {
      this.city_id = e.city_id;
      console.log(this.city_id)
      this.$emit('handleCityChange', this.city_id)
    }
  },
}
</script>

<style lang="scss" scoped>
.tabs{
  display: flex;
  margin-bottom: 15px;
  max-width: 100%;
  //overflow-x: scroll;
  .item{
    margin-right: 30px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid #ddd;
    color: #999;
    //border-radius: 7px;
    cursor: pointer;
    //padding: 0 12px;
    font-size: 16px;
    white-space: nowrap;
    &.active{
      border-bottom: 2px solid #409EFF;
      color: #409EFF;
    }
  }
}
</style>