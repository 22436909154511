<template>
	<div>
		<el-card>
			<el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top: 10px;">
				<el-tab-pane name="service_tab" :label="tabName">
					<el-table :data="serviceList" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" @selection-change="handleSelectionChange">
						<el-table-column type="selection" prop="prop" label="label"></el-table-column>
						<el-table-column prop="service_id" label="ID" width="100"></el-table-column>
						<el-table-column label="封面">
							<template slot-scope="scope">
								<div>
									<img :src="scope.row.service_logo" alt="" style="width:65px;height:65px">
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="service_name" label="服务名称"></el-table-column>
						<el-table-column label="上架">
							<template slot-scope="scope">
								<div>
									<el-switch v-model="scope.row.is_on_sale" @change="changeState(scope.row)"></el-switch>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="服务价格">
							<template slot-scope="scope">
								<div>
									<el-input v-model="scope.row.service_price" placeholder="请输入服务价格"></el-input>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="原价">
							<template slot-scope="scope">
								<div>
									<el-input v-model="scope.row.real_price" placeholder="请输入原价"></el-input>
								</div>
							</template>
						</el-table-column>
					</el-table>
					<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
					<el-row class="left">
						<el-col :span="24">
							<el-button type="primary" @click="subCitySetup">设置</el-button>
						</el-col>
					</el-row>
				</el-tab-pane>
				<el-tab-pane label="分佣设置" name="分佣设置">
          <ServiceTypeScroll is-http @handleServiceTypeChange="typeChange"></ServiceTypeScroll>
					<el-form :rules="yjszRules" :model="yjsz" ref="yjszForm" label-width="110px">
				      	<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		渠道推广员推广
						  	</div>
						  </el-col>
						</el-row>
				      	<el-row>
							<el-col :span="8">
								<el-form-item label="一级分佣比例" prop="fypb_one" label-width="150px">
									<el-input type="number" v-model="yjsz.fypb_one" placeholder="请输入分佣比例" min="1" max="30" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="7">
				              	<el-form-item label="二级分佣比例" prop="fypb_two" label-width="150px">
									<el-input type="number" v-model="yjsz.fypb_two" placeholder="请输入分佣比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
				              	</el-form-item>
							</el-col>
							<el-col :span="1">
								<el-tooltip class="item" effect="dark" content="二级分佣为一级分佣基础上的百分比" placement="right">
				              		<i class="el-icon-question" style="font-size: 18px"></i>
				              	</el-tooltip>
							</el-col>
						</el-row>
				      	<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		经纪人推广
						  	</div>
						  </el-col>
						</el-row>
				      	<el-row>
<!--							<el-col :span="6">-->
<!--								<el-form-item label="技师前" prop="jingli_jishi_fybs" label-width="80px">-->
<!--									<el-input type="number" v-model="yjsz.jingli_jishi_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>-->
<!--								</el-form-item>-->
<!--							</el-col>-->
							<el-col :span="6">
								<el-form-item label="分成比例" prop="jingli_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jingli_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="jingli_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jingli_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jingli_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jingli_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
				      	<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		技师推广
						  	</div>
						  </el-col>
						</el-row>
				      	<el-row>
							<el-col :span="6">
								<el-form-item label="技师前" prop="jishi_jishi_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jishi_jishi_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jishi_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jishi_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="jishi_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jishi_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jishi_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jishi_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		门店推广
						  	</div>
						  </el-col>
						</el-row>
				      	<el-row>
							<el-col :span="6">
								<el-form-item label="技师前" prop="store_jishi_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.store_jishi_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="store_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.store_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="store_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.store_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="store_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.store_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<div class="info">
					        <p>技师等级</p>
				      	</div>
				      	<el-row>
							<el-col v-for="(item,index) in yjsz.grade_list" :span="8">
								<el-form-item :label="item.grade_name" label-width="60px">
									<el-input type="number" v-model="item.fencheng_bfb" placeholder="请输入分佣比例" style="width: 60%;" min="1" max="100" show-word-limit></el-input>&nbsp;&nbsp;%
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<el-row class="left">
						<el-col :span="24">
							<el-button type="primary" @click="subFySetup">设置</el-button>
						</el-col>
					</el-row>
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination/Pagination.vue'
  import ServiceTypeScroll from "@/components/Page/ServiceTypeScroll.vue";
	let that;
	export default {
		components: {
      ServiceTypeScroll,
			Pagination
		},
		data() {
			return {
        queryParams: {
          type_id: 0
        },
				total: 0,
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				activeName: 'service_tab',
				tabName: '服务设置',
				serviceList: [],
				chooseList: [],
				yjsz: {
					fypb_one: '',
					fypb_two: '',
					jingli_jishi_fybs: 1,
					jingli_jishi_fybl: 20,
					jingli_user_fybs: 1,
					jingli_user_fybl: 20,
					jishi_jishi_fybs: 1,
					jishi_jishi_fybl: 20,
					jishi_user_fybs: 1,
					jishi_user_fybl: 20,
					store_jishi_fybs: 1,
					store_jishi_fybl: 20,
					store_user_fybs: 1,
					store_user_fybl: 20,
					grade_list: []
				},
				tgFunArr: [],
				yjszRules: {
					fypb_one: [{
						required: true,
						message: '请输入分佣比例',
						trigger: 'blur'
					}],
				},
			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getService();
				this.tabName = "【"+this.$route.query.cityName+"】"+this.tabName;
			}
		},
		computed: {},
		methods: {
			getService() {
				var url = 'city/service_list';
				let params = {
					city_id: this.$route.query.cityId,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.serviceList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getCityFenyong(){
				var url = 'city/fenyong_detail';
				let params = {
					city_id: this.$route.query.cityId,
					type_id: this.queryParams.type_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.yjsz = res.detail;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getService();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getService();
			},
			handleClick(tab, event) {
				//console.log(tab, event);
			},
			handleSelectionChange(val) {
				this.chooseList = val
			},
			changeState(row) {},
			subCitySetup() {
				if(this.chooseList.length == 0) {
					this.$message.error("请至少选择一行");
					return;
				}
				try {
					this.chooseList.forEach(item => {
						let msg_str = 'ID【' + item.service_id + '】，服务【' + item.service_name + '】';
						if(!item.service_price) {
							throw new Error(msg_str + ',服务价格不能为空');
						}
						if(!/^-?\d+\.?\d{0,2}$/.test(item.service_price)) {
							throw new Error(msg_str + ',服务价格请输入合法的金额数字，最多两位小数');
							return;
						}
						if(!item.real_price) {
							throw new Error(msg_str + ',服务原价不能为空');
							return;
						}
						if(!/^-?\d+\.?\d{0,2}$/.test(item.real_price)) {
							throw new Error(msg_str + ',服务原价请输入合法的金额数字，最多两位小数');
							return;
						}
					});
					var url = 'city/serviceAddOrUpdate';
					let params = {
						city_id: that.$route.query.cityId,
						choose_list: JSON.stringify(that.chooseList),
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('设置成功');
							that.getService();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				} catch(e) {
					this.$message.error(e.message);
					return;
				}
			},
			subFySetup() {
				this.$refs.yjszForm.validate(valid => {
					if(!valid) return;
					that.yjsz.city_id = that.$route.query.cityId;
					that.yjsz.grade_json = JSON.stringify(that.yjsz.grade_list);
					var url = 'city/fenyong_submit';
					let params = that.yjsz;
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('设置成功');
							that.getCityFenyong();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				})
			},
      typeChange(e) {
        this.queryParams.type_id = e
        this.getCityFenyong();
      }
		}
	}
</script>

<style lang="scss" scoped>
	@import './Settings_city_fenyong.scss';
</style>