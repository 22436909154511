<template>
	<div>
		<el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="服务名称">
              <el-input placeholder="请输入服务名称" v-model="queryParams.service_name" clearable @clear="getService"></el-input>
            </el-form-item>
            <el-form-item label="服务类型">
              <el-select v-model="queryParams.type_id" placeholder="请选择服务类型" clearable width="100%" style="display: block;">
                <el-option v-for="item in typeList" :key="item.type_id" :label="item.type_name" :value="item.type_id"/>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
              <el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
              <el-button type="primary" icon="el-icon-plus" @click="add_service">新增服务</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
			<!-- 服务列表 -->
			<el-table :data="serviceTable" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="service_id" label="ID" width="100px"></el-table-column>
				<el-table-column label="封面图">
					<template slot-scope="scope">
						<div>
							<el-avatar shape="square" size="large" :src="scope.row.service_logo"></el-avatar>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="service_name" label="服务名称"></el-table-column>
				<el-table-column prop="service_sort" label="排序值"></el-table-column>
        <el-table-column prop="type_name" label="服务类型" ></el-table-column>
				<el-table-column label="是否上架">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.is_on_sale" @change="changeState(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="创建时间"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-edit" @click="editServiceById(scope.row.service_id)"></el-button>
						<el-button size="mini" type="danger" icon="el-icon-delete" @click="removeService(scope.row.service_id)"></el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	import {serviceTypeAll} from "@/api/request/service";

  let that;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		data() {
			return {
				total: 0,
        queryParams: {
          type_id: "",
          service_name: ""
        },
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				serviceTable: [],
        typeList: [],
			};
		},
		components: {
			Pagination
		},
		created() {
			if (!this.$store.state.userInfo) {
		        // 防止未登录
		        this.$router.push({path: '/login'})
		    }else{
		    	that = this;
				this.getService();
				this.getServiceType();
			}
		},
		methods: {
      async getServiceType() {
        const res = await serviceTypeAll({})
        if (res.code!==200) this.$message.error(res.msg);
        this.typeList = res.data;
      },
			getService() {
				var url = 'service/service_list';
				let params = {
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
          ...this.queryParams
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.serviceTable = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
      //查询
      search() {
        this.queryInfo.pagenum = 1;
        this.getService();
      },
      // 重置
      reset() {
        this.queryInfo.pagenum = 1;
        this.queryParams = {
          type_id: "",
          service_name: ""
        };
        this.getService();
      },
			//新增服务
			add_service() {
				this.$router.push('/service_add/add')
			},
			//switch发生变化
			changeState(item) {
				var url = 'service/updateOnSale';
				let params = {
					service_id: item.service_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						this.$message.success("操作成功");
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					this.$message.error('网络错误');
				});
			},
			//编辑
			editServiceById(id) {
				// console.log(id);
				this.$router.push({
					path: '/service_add/add',
					query: {
						id
					}
				})
			},
			//删除
			removeService(id) {
				this.$confirm("你确认要删除，删除后数据将无法恢复！", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						//删除操作
						var url = 'service/delete_service';
						let params = {
							service_id: id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getService();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					})
					.catch(() => {
						this.$message.info("已取消删除");
					});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getService();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getService();
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "./Service_add.scss";
</style>