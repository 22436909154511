<template>
	<div>
		<el-card>
          <CityScroll :list="city_list" :id="queryParams.city_id" @handleCityChange="cityChange"></CityScroll>
          <ServiceTypeScroll is-http @handleServiceTypeChange="typeChange" :user="user"></ServiceTypeScroll>
					<el-form :rules="yjszRules" :model="yjsz" ref="yjszForm" label-width="110px">
				      	<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		渠道推广员推广
						  	</div>
						  </el-col>
						</el-row>
				      	<el-row>
							<el-col :span="8">
								<el-form-item label="一级分佣比例" prop="fypb_one" label-width="150px">
									<el-input type="number" v-model="yjsz.fypb_one" placeholder="请输入分佣比例" min="1" max="30" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="7">
				              	<el-form-item label="二级分佣比例" prop="fypb_two" label-width="150px">
									<el-input type="number" v-model="yjsz.fypb_two" placeholder="请输入分佣比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
				              	</el-form-item>
							</el-col>
							<el-col :span="1">
								<el-tooltip class="item" effect="dark" content="二级分佣为一级分佣基础上的百分比" placement="right">
				              		<i class="el-icon-question" style="font-size: 18px"></i>
				              	</el-tooltip>
							</el-col>
						</el-row>
				      	<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		经纪人推广
						  	</div>
						  </el-col>
						</el-row>
            <el-row>
							<el-col :span="6">
								<el-form-item label="分成比例" prop="jingli_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jingli_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="jingli_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jingli_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jingli_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jingli_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
            <el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		技师推广
						  	</div>
						  </el-col>
						</el-row>
            <el-row>
							<el-col :span="6">
								<el-form-item label="技师前" prop="jishi_jishi_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jishi_jishi_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jishi_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jishi_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="jishi_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.jishi_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="jishi_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.jishi_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row class="info">
						  <el-col :span="24">
						  	<div class="custom-item">
						  		门店推广
						  	</div>
						  </el-col>
						</el-row>
            <el-row>
							<el-col :span="6">
								<el-form-item label="技师前" prop="store_jishi_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.store_jishi_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="store_jishi_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.store_jishi_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="用户前" prop="store_user_fybs" label-width="80px">
									<el-input type="number" v-model="yjsz.store_user_fybs" placeholder="请输入消费笔数" min="1" show-word-limit></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="笔，分成比例" prop="store_user_fybl" label-width="120px">
									<el-input type="number" v-model="yjsz.store_user_fybl" placeholder="请输入分成比例" min="1" max="100" show-word-limit>
										<template slot="append">%</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<div class="info">
					        <p>技师等级</p>
				      	</div>
				      	<el-row>
							<el-col v-for="(item,index) in yjsz.grade_list" :span="8">
								<el-form-item :label="item.grade_name" label-width="60px">
									<el-input type="number" v-model="item.fencheng_bfb" placeholder="请输入分佣比例" style="width: 60%;" min="1" max="100" show-word-limit></el-input>&nbsp;&nbsp;%
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<el-row class="left">
						<el-col :span="24">
							<el-button type="primary" @click="subFySetup">设置</el-button>
						</el-col>
					</el-row>
		</el-card>
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination/Pagination.vue'
  import ServiceTypeScroll from "@/components/Page/ServiceTypeScroll.vue";
  import CityScroll from "@/components/Page/CityScroll.vue";
	export default {
		components: {
      CityScroll,
      ServiceTypeScroll,
			Pagination
		},
		data() {
			return {
        user: {
          user_id: 0,
          user_type: 5,
        },
        queryParams: {
          city_id: 0,
          type_id: 0
        },
				yjsz: {
					fypb_one: '',
					fypb_two: '',
					jingli_jishi_fybs: 1,
					jingli_jishi_fybl: 20,
					jingli_user_fybs: 1,
					jingli_user_fybl: 20,
					jishi_jishi_fybs: 1,
					jishi_jishi_fybl: 20,
					jishi_user_fybs: 1,
					jishi_user_fybl: 20,
					store_jishi_fybs: 1,
					store_jishi_fybl: 20,
					store_user_fybs: 1,
					store_user_fybl: 20,
					grade_list: []
				},
				yjszRules: {
					fypb_one: [{required: true, message: '请输入分佣比例', trigger: 'blur'}],
				},
        city_list: []
			}
		},
		created() {
      console.log("【0】",this.$route.query.city_list)
      this.city_list = this.$route.query.city_list;
      this.user.user_id = this.$route.query.admin_id;
      if (this.city_list.length > 0) {
        this.queryParams.city_id = this.city_list[0].city_id;
      }
      console.log("【1】",this.city_list,this.queryParams.city_id)
		},
		computed: {},
		methods: {
			getCityFenyong(){
				var url = 'city_partner/fenyong_detail';
				let params = {
          partner_id: this.user.user_id,
					city_id: this.queryParams.city_id,
					type_id: this.queryParams.type_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
            this.yjsz = res.detail;
					} else {
            this.$message.error(res.msg);
					}
				}).catch((err) => {
          this.$message.error('网络错误');
				});
			},
			subFySetup() {
        // console.log(this.yjsz);return;
				this.$refs.yjszForm.validate(valid => {
					if(!valid) return;
          this.yjsz.grade_json = JSON.stringify(this.yjsz.grade_list);
					var url = 'city_partner/fenyong_submit';
					let params = this.yjsz;
          this.fd_post(url, params).then((res) => {
						if(res.status) {
              this.$message.success('设置成功');
              this.getCityFenyong();
						} else {
              this.$message.error(res.msg);
						}
					}).catch((err) => {
            this.$message.error('网络错误');
					});
				})
			},
      cityChange(e){
        this.queryParams.city_id = e
        this.getCityFenyong();
      },
      typeChange(e) {
        this.queryParams.type_id = e
        this.getCityFenyong();
      }
		}
	}
</script>

<style lang="scss" scoped>
.left{
  text-align: left;
}
.margin_left{
  margin-left: 30px;
}
.warning{
  height: 60px;
  padding-left: 20px;
  border-radius: 3px;
  background-color: #FFF6F7;
  border-left: 5px solid #FE6C6F;
  p{
    color: #666;
    line-height: 60px;
    text-align:left;
    font-size: 14px;
  }
}
.info {
  padding: 8px 16px;
  background-color: #ecf8ff;
  border-radius: 4px;
  border-left: 5px solid #50bfff;
  margin: 20px 0;
  text-align: left;
  .el-col{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header{
  margin: 20px;
  height: 35px;
  border-top: 2px solid #F5F7FA;
  .title{
    width: 86px;
    height: 30px;
    line-height: 30px;
    background-color: #F5F7FA;
    color: #609BED;
    text-align: center;
  }
}
.el-input__inner[type="number"]::-webkit-outer-spin-button,
::v-deep .el-input__inner[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
.fare-setting{
  position: relative;
  box-sizing: border-box;
  padding: 10px 50px 10px 10px;
  text-align: left;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(134,134,134,0.5);
  display: flex;
  flex: 1;
  margin: 5px;
  align-items: center;
  justify-content: space-between;
  .el-button{
    position: absolute;
    right: 5px;
    top: 5px;
  }
  .items{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .title{
      width: 65px;
    }
    .el-input{
      width: 65%;
    }
    .el-time-select{
      width: 20%;
    }
  }
}
</style>